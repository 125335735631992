import { Typography } from "@mui/material";
import { components } from "../../../api/schema";
import InfoIcon from "../../../Icons/InfoIcon";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import CustomCheckBox from "../../CustomCheckBox";
import Tooltip from "../../Tooltip";
import useSetRebalanceSpec from "./useSetRebalanceSpec";

const ICON_SIZE = 12;

interface Props {
    spec: components["schemas"]["Node_detailsRebalancePlanSpec"] | undefined;
}

const RemoveNodesWithKubeSystemSwitch = ({ spec }: Props) => {
    const { userRole } = useGetUserRole();
    const setRebalanceSpec = useSetRebalanceSpec();

    return (
        <Tooltip
            title={
                <Typography variant="caption">
                    If enabled, ScaleOps will enable the removal of nodes with kube-system.
                </Typography>
            }
            maxWidth={500}
            className="flex gap-2 items-center justify-start w-fit"
            placement="right"
        >
            <CustomCheckBox
                value={!spec?.skipNodesWithSystemPods}
                onClick={(checked) => {
                    setRebalanceSpec.mutate({
                        ...spec,
                        skipNodesWithSystemPods: !checked,
                    });
                }}
                disabled={userRole !== "Admin"}
            />
            <Typography variant="body2" className="flex gap-[2px] items-center justify-start cursor-default">
                <span>Scale down nodes with kube-system pods</span>
                <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
            </Typography>
        </Tooltip>
    );
};

export default RemoveNodesWithKubeSystemSwitch;